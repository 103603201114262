




























/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/Nav-bar.vue";
import BlockBox from "@/components/common/Block-box.vue";
import AppBlockNormal from "@/components/block/App-block-normal.vue";
import Comment from "@/components/widget/comment.vue";
import InstallBtn from "@/components/InstallBtn.vue";
import mixins from "@/mixin/index.ts";
import routerMixin from "@/mixin/router-mixin.ts";
import updateTdk from "@/utils/tdk";

import { getHomePageData } from "@/api";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "CommentDetail",
  components: { NavBar, BlockBox, AppBlockNormal, Comment, InstallBtn },
  mixins: [mixins, routerMixin]
})
export default class WealDetails extends Vue {
  // 初始数据可以直接声明为实例的属性
  private info: any = {};
  private replys: any[] = [];
  private navs: any[] = [{ name: "福利详情", link: "" }];
  private isHideDownload: boolean = false;
  private commentTitle: string = '';
  private tdk(self: any) {
    return {
      title: `-${self.commentTitle}`
    };
  }
  private serverPrefetch(): any {
    return this.fetchData();
  }
  get getDetail(): any {
    return this.$store.state.pageDatas.commentDetailData || {};
  }
  private fetchData(): any {
    const self: any = this;
    // this.$store.commit('setCommentDetailData', {})

    const contentId: number = Number(self.$route.query.contentId) || 0;
    if (!contentId) {
      this.$router.push({
        name: "index"
      });
      return;
    }
    self.$doMotion(this, "LOADING_SWITCH", true);
    return this.$store
      .dispatch("fetchCommentDetail", {
        context: this,
        params: { evaluate_id: contentId, start: 0, max: 50 }
      })
      .then(res => {
        const data = res.value;
        if (data) {
          data.data_source = "commentDetail";
          data.replys = data.replys || [];
          self.commentTitle = data.name;
          self.$store.commit("setCommentDetailData", data);
          self.noData = false;
          // 判断此时的高度
          this.$nextTick(() => {
            // 判断当前的高度是否太小
            if (!self.isServer && (document as any).getElementById("app").scrollHeight < (document.body.clientHeight + 20)
            ) {
              this.isHideDownload = true;
            }
          });
        }
        /* self.block = (data || []).map((it: any) => {
        it.data_source = 'wealDetail';
        return it;
      }); */
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || '服务器错误，请刷新！');
      })
      .finally(() => {
        self.$doMotion(self, "LOADING_SWITCH", false);
        updateTdk(self);
      });
  }
  private mounted(): void {
    const self: any = this;
    self.navs.unshift({
      name: self.utils.getUrlParam("source_block_name"),
      link: "/weal/list"
    });
    const contentId: number = Number(self.$route.query.contentId) || 0;
    if (!contentId) {
      this.$router.replace({
        name: "index"
      });
      return;
    }
    if (!self.getDetail.data_source) {
      // 是否有值
      self.fetchData();
    }
  }
  private routerBack(): void {
    const self: any = this;
    if (window.history.length <= 1) {
      self.$router.push({ path: "/" });
    } else {
      self.$router.go(-1);
    }
  }
  private destroyed() {
    this.$store.commit('setCommentDetailData', {}) // 修改store
  }
}
